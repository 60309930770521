.c-products {
  &--item {
    font-weight: 300;
    line-height: 1.3;
    color: #000000;
    &-title {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    &-text {
      font-size: 13.5px;
    }
    &-label {
      padding: 9px 8px 6px 12px;
      background-color: #f7f2eb;
      font-size: 11.5px;
      line-height: 1;
      font-weight: 300;
      text-align: center;
      color: #000000;
    }
    &-border {
      height: 6px;
    }
  }
}

.c-featured {
  &--category {
    &:not(:first-child) {
      display: none;
    }
  }
}

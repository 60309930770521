@use 'components';
html,
body {
  font-family: "Fabriga", Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #000;
  background: transparent;
  overflow: visible !important;
}
html {
  background-color: #fffdf7;
}
body {
  position: relative;
}

a,
.hover-font {
  &:hover {
    font-weight: 400;
    * {
      font-weight: 400;
    }
  }
}
.storefront-breadcrumb {
  display: none;
}
.lazy-loaded,
.lazyloaded {
  @apply w-full;
}
input {
  &[type="search"] {
    @apply bg-off-white;
  }
}
.wpcf7-form {
  .col-2 {
    @apply grid grid-cols-2 gap-5;
  }
  input[type=text], input[type=email], textarea {
      @include components.input;
      @apply rounded-none border-black;
  }
  input[type=submit] {
    @include components.button-secondary;
    @apply float-right;
  }
  .your-file-label {
    @apply cursor-pointer mt-14 block;
    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13' /%3E%3C/svg%3E");
      @apply w-4 h-4 ml-2 inline-block;
    }
  }
  input {
    @apply mb-2;
    &[type=file] {
      @apply text-xs;
    }
  }
  label {
    @apply text-base;
    
  }
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
  &:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #f7f2eb;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.logo {
  .blink {
    animation: blinker 0.6s steps(1) infinite alternate;
  }
}
.js-editor {
  &:hover {
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
  }
}

.u-fw {
  flex-wrap: wrap;
  @screen mobile {
    &__mobile {
      flex-wrap: wrap;
    }
  }
}

a,
.menu li {
  svg:not(.logo) {
    path,
    circle {
      stroke-width: 1px;
      stroke: #000;
    }
  }
  &:hover {
    svg:not(.logo) {
      path,
      line,
      circle {
        stroke-width: 2px;
        stroke: #000;
      }
      .fill {
        stroke-width: 0.5px;
      }
    }
  }
}

.entry-title {
  display: none;
}
.zigzag:after {
  content: " ";
  display: block;
  position: relative;
  width: 100%;
  bottom: -10px;
  height: 10px;
  background: linear-gradient(-45deg, transparent 75%, #f7f2eb 75%) 0 50%,
    linear-gradient(45deg, transparent 75%, #f7f2eb 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 10px 10px, 10px 10px;
}

.zigzag:before {
  content: " ";
  display: block;
  position: relative;
  width: 100%;
  top: -10px;
  height: 10px;
  background: linear-gradient(135deg, transparent 75%, #f7f2eb 75%) -15px 100%,
    linear-gradient(-135deg, transparent 75%, #f7f2eb 75%) -15px 100%;
  background-repeat: repeat-x;
  background-size: 10px 10px, 10px 10px;
}

.ticker {
  margin: 20px 0;
  @screen sm {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  background-color: #ffff00;
  padding: 6px 0;
  display: flex;
  position: relative;
  &:before {
    content: "";
    height: 100%;
    position: absolute;
    left: -100vw;
    top: 0;
    width: 300vw;
    display: block;
    background-color: #ffff00;
  }
  &__list {
    display: flex;
    animation: ticker 15s infinite linear forwards;
  }
  &:hover {
    animation-play-state: paused;
  }
  &__item {
    margin-right: 20px;
  }
  .o-media__fluid {
    white-space: nowrap;
  }
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


/**
 * WooCommerce Price Filter
 */
.widget_price_filter {

	.price_slider {
		margin-bottom: 1.5em;
	}

	.price_slider_amount {
		text-align: right;
		line-height: 2.4;

		.button {
			float: left;
		}
	}

	.ui-slider {
		position: relative;
		text-align: left;
	}

	.ui-slider .ui-slider-handle {
		position: absolute;
		z-index: 2;
		width: 1em;
		height: 1em;
		cursor: ew-resize;
		outline: none;
		background: $color__link;
		box-sizing: border-box;
		margin-top: -0.25em;
		opacity: 1;

		&:last-child {
			margin-left: -1em;
		}

		&:hover,
		&.ui-state-active {
			box-shadow: 0 0 0 0.25em rgba(#000, 0.1);
		}
	}

	.ui-slider .ui-slider-range {
		position: absolute;
		z-index: 1;
		display: block;
		border: 0;
		background: $color__link;
	}

	.price_slider_wrapper .ui-widget-content {
		background: rgba(0, 0, 0, 0.1);
	}

	.ui-slider-horizontal {
		height: 0.5em;
	}

	.ui-slider-horizontal .ui-slider-range {
		height: 100%;
	}
}

.single-product {
  div.product {
    position: relative;

    .woocommerce-product-gallery {
      position: relative;
      float: left;

      .woocommerce-product-gallery__trigger {
        position: absolute;
        top: 2em;
        right: 1em;
        display: block;
        z-index: 99;
      }

      .flex-viewport {
        margin-bottom: 1em;
      }

      .flex-control-thumbs {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          cursor: pointer;
          float: left;

          img {
            opacity: 0.5;

            &.flex-active {
              opacity: 1;
            }
          }

          &:hover {
            img {
              opacity: 1;
            }
          }
        }
      }

      @for $i from 2 through 5 {
        &.woocommerce-product-gallery--columns-#{$i} {
          .flex-control-thumbs {
            li {
              @include column-width($i);

              &:nth-child(#{$i}n) {
                margin-right: 0;
              }

              &:nth-child(#{$i}n + 1) {
                clear: both;
              }
            }
          }
        }
      }
    }
  }
  .cart {
    @apply my-4;
    .quantity {
      @apply mr-4;
    }
    #wc-stripe-payment-request-button-separator {
      @apply my-2 #{!important};
    }
  }
  .variations {
    tr {
      @apply grid text-left;
    }
    select {
      @apply mt-1 block w-full border border-black py-2 pl-3 pr-10 text-base focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm #{!important};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
      background-position: right 0.5rem center;
      background-repeat: no-repeat;
      background-size: 1.5em 1.5em;
      padding-right: 2.5rem;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';
    }
  }
  .single_variation_wrap {
	.variations_button {
		@apply grid grid-cols-[74px_1fr] gap-y-2;
	}
	#wc-stripe-payment-request-wrapper {
		@apply pt-0 #{!important};
	}
  }
}
.stock {
  &:empty::before {
    display: none;
  }

  &.in-stock {
    color: $woocommerce__color-success;
  }

  &.out-of-stock {
    color: $woocommerce__color-error;
  }
}
.storefront-sticky-add-to-cart {
  @apply hidden;
}

$yellow: #ffff00;
$green: #00863c;
$pink: #f3b7b6;
$red: #e04622;
$blue: #3d7ec1;

@mixin medium {
  font-size: 19.5px;
  font-weight: 300;
  line-height: 2.03;
  text-align: center;
  color: #000000;
}

@mixin normal {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.69;
  text-align: left;
  color: #000000;
}

.c-header {
  &--branding {
    max-width: 375px;
    position: relative;
    a {
      display: block;
      line-height: 1;
    }
    @screen mobile {
      max-width: 180px;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      background-color: $yellow;
      @apply pr-2;
      @screen sm {
        @apply -bottom-2 -top-2 -left-4 -right-5;
      }
    }
    svg {
      max-width: 100%;
      width: 100%;
      display: block;
    }
  }
  &--logo {
    max-width: 333px;
  }
  &--search {
    form {
      margin-bottom: 0;
      position: relative;
      .search-submit {
        position: absolute;
        height: 100%;
        background-color: transparent;
        color: #000;
        right: 0px;
        font-size: 20px;
        padding: 0;
        text-indent: -9999px;
        width: 37px;
        background-image: url(./assets/search.svg);
        background-position: center center;
        background-repeat: no-repeat;
      }
      .search-field {
        margin: 0;
        padding: 6px 10px;
        @apply mb-1;
        &::placeholder {
          font-size: 16px;
          line-height: 1;
          letter-spacing: 0.16px;
          color: #cbc2ba;
        }
      }
    }
  }
  &--right {
    > div {
      margin: 0 10px;
      @screen sm {
        margin: 0 20px;
      }
      position: relative;
    }
  }
  &--contact {
    max-width: 68px;
  }
  &--cart {
    svg {
      min-width: 31px;
    }
  }
  &--account {
    svg {
      min-width: 24px;
    }
  }
  nav {
    .menu {
      &-item {
        font-size: 22px;
        font-weight: 300;
        line-height: 1.11;
        color: #000000;
      }
    }
  }
  .widget_shopping_cart {
    @apply hidden;
  }
  @screen sm {
    .site-header-cart {
      &:hover,
      &.focus {
        .widget_shopping_cart {
          width: 260px;
          @apply block right-0 left-auto bg-off-white;
          .woocommerce-mini-cart {
            &-item {
              @apply p-2;
              &:nth-child(even) {
                @apply bg-yellow-50;
              }
              .remove {
                @apply float-left;
              }
              a, span {
                @apply text-xs;
              }
              a:nth-child(2) {
                @apply flex;
              }
              img {
                @apply h-10 w-10 mr-1;
              }
            }
            &__total, &__buttons {
              @apply block p-2 text-xs;
            }
          }
        }
      }
    }
  }
}

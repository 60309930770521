.circle {
  &-text {
    animation: turn 8s linear infinite;
    @apply w-24 h-24 bg-off-white rounded-full relative text-xs;
  }
  &-sign {
    animation: turn 8s linear reverse infinite;
  }

  .char {
    --units: 1;
    --rotationUnit: calc((340deg / var(--noOfItems, 37)) * var(--char-index, 1));
    transform: rotate(var(--rotationUnit));
    transform-origin: center;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    top: 3px;
    left: 3px;
    text-align: center;
    // Required to override splitting.js styles
    display: block !important;
    position: absolute !important;

    &::before,
    &::after {
      display: none;
    }
  }
}


@keyframes turn {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
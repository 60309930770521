/**************************\
  Basic Modal Styles
\**************************/

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#ccd3d1, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal__container {
  background-color: #F7F2EB;
  text-align: center;
  padding: 25px 50px;
  max-width: 620px;
  max-height: 100vh;
  box-sizing: border-box;
  position: relative;
  &:before,
  &:after {
    position: absolute;
    display: block;
    top: 0;
    left: -22px;
    content: "";
    width: 22px;
    height: 100%;
    background-image: url("./assets/newsletter-bg.png");
  }
  &:after {
    left: auto;
    right: -22px;
    background-image: url("./assets/newsletter-bg-2.png");
  }
  form {
    position: relative;
    max-width: 320px;
    margin: 0 auto;
  }
  input {
    &[type=email] {
      background-color: #fff;
      width: 100%;
    }
    &[type=submit] {
      height: 100%;
      color: #000;
      background-color: transparent;
      position: absolute;
      right: 0;
    }
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__close {
  background: transparent;
  border: 0;
  position: absolute;
  top: 20px;
  right: 40px;
}

.modal__header .modal__close:before {
  content: "\2715";
  color: #000;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}


.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

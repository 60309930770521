.single-product {
    .c-cart {
        .stock {
            @apply hidden;
        }

        .cart {
            @apply grid grid-cols-3;

            .quantity {
                @apply col-span-1;
            }

            #wc-stripe-payment-request-wrapper,
            #wc-stripe-payment-request-button-separator,
            .single_add_to_cart_button {
                @apply col-span-2 col-start-2;
            }

            #wc-stripe-payment-request-wrapper {
                padding-top: 0 !important;
            }
        }

        &.low-stock {
            .cart {
                @apply grid-cols-2;

                #wc-stripe-payment-request-wrapper,
                #wc-stripe-payment-request-button-separator,
                .single_add_to_cart_button {
                    @apply col-start-1;
                }
            }
        }
    }
}
.woocommerce-checkout {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply hidden;
  }

  .checkout {
    @apply grid md:grid-cols-2 gap-6;
    h3 {
      @apply hidden;
    }
    .woocommerce-NoticeGroup-checkout {
      @apply col-span-2;
    }
    #ship-to-different-address {
      @apply block;
    }
    .woocommerce-checkout-review-order {
      @apply md:col-start-2 mt-6;
    }
    label {
      @apply block mt-3;
    }
    input[type="text"],
    .input-text,
    textarea {
      @apply bg-yellow-50 border-gray-400 border w-full mb-2 py-1 px-2 rounded;
    }
  }
  .woocommerce-shipping-methods {
    li {
      @apply flex;
    }
    input {
      @apply mt-1 mr-2;
    }
    label {
      @apply mt-0 text-sm #{!important};
    }
    .includes_tax {
      @apply text-xs block;
    }
  }
  // .woocommerce-checkout-review-order-table {
  //   td {
  //     min-width: 220px;
  //   }
  // }
  .wc_payment_method {
    &.payment_method_stripe {
      label {
        @apply flex #{!important};
        img {
          @apply ml-1;
        }
      }
      #stripe-payment-data {
        p {
          @apply text-xs;
        }
      }
    }
  }
  .woocommerce-privacy-policy-text {
    @apply text-xs;
  }
  .place-order {
    button {
      @apply text-xl w-full p-4 mt-14;
    }
  }
}

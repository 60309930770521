@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/FabrigaMedium-Italic.woff2') format('woff2'),
        url('./fonts/FabrigaMedium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/Fabriga-Bold.woff2') format('woff2'),
        url('./fonts/Fabriga-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/Fabriga.woff2') format('woff2'),
        url('./fonts/Fabriga.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/FabrigaLight-Italic.woff2') format('woff2'),
        url('./fonts/FabrigaLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/Fabriga-Italic.woff2') format('woff2'),
        url('./fonts/Fabriga-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/FabrigaBlack.woff2') format('woff2'),
        url('./fonts/FabrigaBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/FabrigaLight.woff2') format('woff2'),
        url('./fonts/FabrigaLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/Fabriga-BoldItalic.woff2') format('woff2'),
        url('./fonts/Fabriga-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/FabrigaBlack-Italic.woff2') format('woff2'),
        url('./fonts/FabrigaBlack-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('./fonts/Gordita-Medium.woff2') format('woff2'),
        url('./fonts/Gordita-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fabriga';
    src: url('./fonts/FabrigaMedium.woff2') format('woff2'),
        url('./fonts/FabrigaMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('./fonts/Gordita-Light.woff2') format('woff2'),
        url('./fonts/Gordita-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('./fonts/Gordita-Regular.woff2') format('woff2'),
        url('./fonts/Gordita-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


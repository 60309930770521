.quantity {
    input {
        background-color: transparent;
        border: 1px solid #000;
        padding: .5em;
        width: auto;
        @apply text-h4;
    }
}
.stock, .price {
    margin-bottom: 0;
}
.c-menu {
  &__mobile {
    position: fixed;
    top: 0;
    left: 30px;
    right: 0;
    bottom: 0;
    background-color: #fffdf7;
    z-index: 10;
    transform: translateX(100vw);
    transition: 400ms;
    &.is-active {
      transform: translateX(0);
      + .c-menu__mobile--overlay {
        opacity: 1;
        z-index: 0;
      }
    }
    &:after {
        content: '';
        top: 0;
        background-size: contain;
        width: 20px;
        height: 100%;
        position: absolute;
        left: -20px;
        background-image: url(./assets/triangle.svg);
    }
    &--overlay {
      transition: 400ms;
      opacity: 0;
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $yellow;
      mix-blend-mode: multiply;
    }
  }
}

.current-menu-item {
  a {
    font-weight: 400;
  }
}
/*
Theme Name: _s

WooCommerce styles override
*/

// WooCommerce color variables
$woocommerce__color-error: #e2401c;
$woocommerce__color-success: #0f834d;
$woocommerce__color-info: #3d9cd2;

// Import variables and mixins
@import "abstracts/abstracts";

/**
 * Shop tables
 */
@import "woocommerce/tables";

/**
 * Products
 */
@import "woocommerce/products";

/**
 * Single product
 */
@import "woocommerce/single-product";

/**
 * Checkout
 */
@import "woocommerce/checkout";

/**
 * General WooCommerce components
 */
@import "woocommerce/components";

/**
 * WooCommerce widgets
 */
@import "woocommerce/widgets";
@import "woocommerce/account";
@import "woocommerce/cart";

/**
 * Grid columns. This setting is shared between
 * iotaCSS grid objects and size, pull & push
 * utilities. You can change it also locally to
 * each module.
 *
 * Type: Unitless Number / List
 */

$iota-global-columns: 5, 12;


/**
 * Global namespace for Objects, Components and 
 * Utilities
 *
 * Type: String
 */

$iota-global-objects-namespace    : 'o-';
$iota-global-utilities-namespace  : 'u-';
$iota-global-components-namespace : 'c-';


/**
 * Default gutters. This setting is shared between
 * multiple objects and utilities as the default value
 * for gutters. You can change it also locally to each
 * module.
 *
 * Type: Number / List / Map
 */

$iota-global-gutter-default: 28px;


/**
 * Enables flexbox across the app. If you do not want
 * all modules to use flexbox you can keep this value
 * false and set it to true separately to each one of
 * them locally.
 *
 * Type: Boolean
 */

$iota-global-flex: true;


/**
 * Enables rtl across the app. If you enable this setting
 * the final CSS will be converted to RTL.
 *
 * Type: Boolean
 */

$iota-global-rtl: false;


/**
 * Default global breakpoints map. These are the
 * default breakpoints map that will be shared across
 * all iotaCSS modules. You can change it also locally
 * to each module.
 *
 * Type: Map
 */

$iota-global-breakpoints: (
  sm  : "screen and ( min-width: 768px )",
  md  : "screen and ( min-width: 1000px )",
  mobile  : "screen and ( max-width: 767px )"
);


/**
 * Global breakpoint suffix naming setting. All breakpoint
 * specific styles have a '@breakpointName' suffix by default.
 * The \ character is used to escape the @ character.
 *
 * Type: String
 */

$iota-global-breakpoint-separator: \@;


/**
 * Global delimiter naming setting for Size, Push and Pull
 * utilities. By default it is '/' (.u-1/2) and you can change
 * it for example to 'of' so that the generated HTML class will be
 * 'u-1of2'.
 *
 * Type: String
 */

$iota-global-delimiter: \/;


@import 'node_modules/iotacss/settings/core';

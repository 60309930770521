%tag {
  border: solid 0.6px #000000;
  padding: 0 2px;
  text-decoration: none !important;
  font-weight: 300;
  @apply mb-2 mr-2 inline-block;
}
.tagged_as [rel=tag] {
  @extend %tag;
}
.c-tag {
  @extend %tag;
  &--ribbon {
    background-color: #f7f2eb;
    padding: 4px 9px;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 5px;
      height: 100%;
      background-image: url(./assets/ribbon-right@2x.png);
      background-size: contain;
      position: absolute;
      right: -5px;
      top: 0px;
      z-index: 9;
    }
    + .c-tag--ribbon {
      margin-left: 10px;
    }
  }
}
@import '~codyhouse-framework/main/assets/css/base/breakpoints';
@import '~codyhouse-framework/main/assets/css/base/mixins';
@import '~codyhouse-framework/main/assets/css/base/colors';
@import '~codyhouse-framework/main/assets/css/base/spacing';
@import '~codyhouse-framework/main/assets/css/base/shared-styles';
/* @import '~codyhouse-framework/main/assets/css/base/typography'; */
@import '~codyhouse-framework/main/assets/css/base/icons';
@import '~codyhouse-framework/main/assets/css/base/buttons';
@import '~codyhouse-framework/main/assets/css/base/forms';
@import '~codyhouse-framework/main/assets/css/base/z-index';
@import '~codyhouse-framework/main/assets/css/base/visibility';
@import '~codyhouse-framework/main/assets/css/base/accessibility';
// @import '~codyhouse-framework/main/assets/css/base/util';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import 'variables';
@import 'settings/core';

@import 'tools/core';
@import 'tools/breakpoint';
@import 'tools/ms';
@import 'tools/rtl';
@import 'tools/type';

@import 'base/initialize';

/* @import 'objects/container'; */
/* @import 'objects/grid'; */
@import 'objects/list';
@import 'objects/media';
/* @import 'objects/type'; */

@import 'utilities/align-items';
/* @import 'utilities/align'; */
@import 'utilities/bgcolor';
@import 'utilities/clearfix';
@import 'utilities/color';
/* @import 'utilities/display'; */
@import 'utilities/flex-direction';
/* @import 'utilities/float'; */
@import 'utilities/justify-content';
@import 'utilities/margin';
@import 'utilities/opacity';
@import 'utilities/padding';
/* @import 'utilities/position'; */
/* @import 'utilities/pull'; */
/* @import 'utilities/push'; */
@import 'utilities/size';
@import 'utilities/text';
/* @import 'utilities/transform'; */
/* @import 'utilities/weight'; */


@import 'fonts';
@import 'components/*.scss';
@import 'pages/product';
@import 'pages/contact';
@import 'woocommerce';
@import 'app';
/*! purgecss start ignore */
@import 'custom-style';
.woocommerce-cart {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-2xl;
  }
  h1,
  h2 {
    @apply mb-4;
  }
  table {
    @apply w-full;
    &.cart {
      thead {
        @apply bg-gray-100;
      }
      th,
      td {
        @apply p-2;
      }
      .product {
        &-thumbnail {
          @apply w-16 h-16 rounded-md;
          &:before {
            content: '' !important;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            @apply bg-yellow-100;
          }
          &:nth-child(odd) {
            @apply bg-yellow-50;
          }
        }
      }
    }
    .actions {
      @apply text-right;
    }
  }
}

.cart_totals {
  @apply max-w-md mr-0 ml-auto;
  table {
    th {
      @apply bg-yellow-200;
    }
    td {
      @apply bg-yellow-50;
      label {
        @apply ml-1;
      }
    }
    td,
    th {
      @apply p-2 align-top;
    }
    .woocommerce-shipping-destination,
    .woocommerce-shipping-calculator {
      @apply text-xs;
    }
  }
  .wc-proceed-to-checkout {
    @apply mt-6;
    .button {
      @apply w-full block;
    }
  }
}
.shop_table {
  th {
    @apply bg-yellow-200;
  }
  td {
    @apply bg-yellow-50;
    label {
      @apply ml-1;
    }
  }
  td,
  th {
    @apply p-2 align-top;
  }
  .woocommerce-shipping-destination,
  .woocommerce-shipping-calculator {
    @apply text-xs;
  }
}

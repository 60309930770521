/* -------------------------------- 

File#: _1_number-input
Title: Number input
Descr: Number input field with custom increment buttons
Usage: codyhouse.co/license

-------------------------------- */

.number-input__btn { // hide custom increment buttons if js is disabled
  display: none;
}

.js {
  .number-input { // basic style
    .form-control::-webkit-inner-spin-button, 
    .form-control::-webkit-outer-spin-button { // hide native increment on webkit
      display: none;
    }

    .form-control { // hide native increment on Firefox 
      -moz-appearance: textfield;
      display: block;
    }
  }

  .number-input__btn { // custom increment buttons 
    display: inline-block; // flex fallback
    display: flex;
  }
}

// default - custom increment buttons inside input
.js .number-input--v1 {
  @apply relative inline-block;

  .form-control {
    width: 100%;
    @apply rounded-none py-3 pl-2 pr-8;
  }

  .number-input__btns {
    position: absolute;
    height: calc(100% - var(--space-xxxs)*2);
    top: var(--space-xxxs);
    right: 0;
    display: flex;
    flex-direction: column;
    @apply flex justify-end w-6 mr-1;
  }

  .number-input__btn { 
    position: relative;
    height: 50%;
    border-radius: var(--radius-sm);
    @apply justify-center items-center w-6;

    .icon {
      display: block;
      font-size: 0.8em; // icon size
      width: 1em;
      height: 1em;
      position: absolute;
      left: calc(50% - 0.5em);
      top: calc(50% - 0.5em);
    }

    &:hover {
      @apply text-opacity-90;
    }

    &:focus {
      outline: none;
      @apply text-opacity-90;

      .icon {
        color: var(--color-white);
      }
    }

    &:active {
      background-color: lightness(var(--color-primary), 0.9);
    }
  }

  .number-input__btn--plus {
    margin-bottom: var(--space-xxxxs);
  }
}

// --v2 - custom increment w/ buttons outside input element
.js .number-input--v2 {
  --number-input-btn-width: 1.6em;
  display: flex;
  align-items: center;
  font-size: 0.875em;

  .form-control {
    margin: 0 var(--space-xxs);
    width: 4em;
    order: 1;
    text-align: center;
  }

  .number-input__btn {
    width: var(--number-input-btn-width);
    height: var(--number-input-btn-width);
    border-radius: 50%;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: var(--color-contrast-lower);

    &:hover {
      background-color: var(--color-contrast-low);
    }

    &:focus {
      outline: none;
      background-color: var(--color-primary);

      .icon {
        color: var(--color-white);
      }
    }

    &:active {
      background-color: lightness(var(--color-primary), 0.9);
    }

    .icon {
      font-size: 12px; // icon size
      width: 1em;
      height: 1em;
    }
  }

  .number-input__btn--plus {
    order: 2;
  }
}
table.shop_table_responsive {

	thead {
		display: none;
	}

	tbody {

		th {
			display: none;
		}
	}

	tr {

		td {
			display: block;
			text-align: right;
			clear: both;

			&::before {
				content: attr(data-title) ": ";
				float: left;
			}

			&.product-remove, &.product-thumbnail {

				a {
					text-align: left;
				}

				&::before {
					display: none;
				}
			}

			&.actions,
			&.download-actions {

				&::before {
					display: none;
				}
			}

			&.download-actions {

				.button {
					display: block;
					text-align: center;
				}
			}
		}
	}
}

@media screen and (min-width: 48em) {

	table.shop_table_responsive {

		thead {
			display: table-header-group;
		}

		tbody {

			th {
				display: table-cell;
			}
		}

		tr {

			th,
			td {
				text-align: left;
			}

			td {
				display: table-cell;

				&::before {
					display: none;
				}
			}
		}
	}
}
